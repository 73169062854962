import { createSlice } from "@reduxjs/toolkit";

const gameReducer = createSlice({
    name: "games",
    initialState: {
        list: [],
        count: 0
    }, reducers: {
        UpdateUserGames(state, action) {
            state.list = Object.assign([], action.payload.list)
            state.count = action.payload.count
        }
    }
})

export const actions = gameReducer.actions;
export default gameReducer.reducer;